import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Link from "../components/Link"

import { extractBlogPosts, getSortedGroups, groupPostsByYear } from "../utils"

import externalLinks from "../content/articles/externalLinks"
import SayHi from "../components/SayHi"
import { Box } from "../components/Box"
import { Heading1, Heading2, Paragraph, TextEm } from "../components/Typography"
import List from "../components/List/List"
import Brain from "../content/images/writings/brain.png"

const ListHeading2 = styled(Heading2)`
  margin:0;
`

const PinBanner = styled(Box)`
  display: flex;
  flex-direction: row;
  @media (max-width: 414px) {
    flex-direction: column;
  
  }
`
const PinPic = styled.div`
  text-align:center;
  width:200px;
  @media (max-width: 414px) {
      margin:0px auto;
  }
  
`

const Archive = ({ data }) => {
  const blogPosts = getSortedGroups(
    groupPostsByYear(extractBlogPosts(data).concat(externalLinks))
  )

  return (
    <Layout>
      <Box maxWidth="640px" marginTop={20} element="section">
        <SEO title="存档" />
        <Heading1> <span role="img">🗂</span> 我的存档</Heading1>
        <PinBanner element="section"  >
       
       <Paragraph>
          我2007年开始用{" "}<Link color="white" href="https://wordpress.org/" underlined target="_blank">WordPress</Link>{" "}搭建博客，现在回看内容实在<TextEm>“辣眼睛</TextEm>，但不觉自己过去幼稚的话，今天的自己怎么会有成长。
        </Paragraph>
        <PinPic><Img fluid={data.file.childImageSharp.fluid} alt="avenirzheng"/></PinPic>
        </PinBanner>
    
        <Box marginTop={10} element="section">


        
        <List title="存档内容 2007年 - 2013年">
        {blogPosts.map(([key, posts]) => {
            return (
              <span title={key}>
                {key <= "2014" ?
                      (<div className="on"></div>) :
                      (<div className="off"></div>)
                  }
                  {posts.map((post, index) => (
                    <List.Item link={post.link} key={index} subtitle={post.date}>
                      <ListHeading2>{post.title}</ListHeading2>
                    </List.Item>
                  ))}
               </span>
            )
          })}
           </List>

        </Box>
        <Box marginTop={32} marginBottom={32} element="section">
          <SayHi />
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {date: {lte: "2014-01-01"}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
          }
          fields {
            slug
          }
        }
      }
    }
    file(relativePath: { eq: "images/writings/brain.png" }) {
      childImageSharp {
        fluid(maxWidth: 300,quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default Archive
